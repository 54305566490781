/* eslint-disable react/no-unescaped-entities */
import React from 'react';

const Rgpd = () => {
    return (
        <div className="main">
        <div className="header">
            <div className = "item1"> BimdigitalConstruction: </div>
            <div className="item2"> Mesures RGPD et traitement de données </div>
        </div>

        <div className="container">
            <h1 className="title"> Politique de confidentialité de l'application BimdigitalConstruction</h1>
            <p>
            Bienvenue sur notre page de conformité au RGPD. Cette politique de confidentialité explique comment nous collectons, traitons et utilisons vos données personnelles sur notre site web. Nous tenons à vous informer sur vos droits et sur la manière dont nous protégeons vos données.
            </p>
            <h1 className="title">I- L'objectif de la politique de confidentialité</h1>
            <p>  Cette politique de confidentialité est établie dans le but d'informer les utilisateurs de l'application BimDigitalconstruction, des modalités suivantes :</p>
            <ul className='ul-rgpd'>
                <li className='li-rgpd'>	Les données personnelles recueillies par le site internet </li>
                <li className='li-rgpd'> L'utilisation faite de ces données</li>
                <li className='li-rgpd'>  L'accès à ces données</li>
                <li className='li-rgpd'>  Les droits que possèdent les utilisateurs du site</li>
                <li className='li-rgpd'> La politique liée à l'utilisation de cookies</li>
            </ul>
        
            <h1 className="title">
                II- Lois applicables
            </h1>
            <p>
                Conformément au Règlement Général sur la Protection des Données, cette politique de confidentialité est sujette aux règlements suivants :
            </p>
            <p>
                Les données personnelles sont :
            </p>
           <ul className='ul-rgpd'>
            <li className='li-rgpd'> traitées de manière licite et transparente </li>
            <li className='li-rgpd'>	collectées à des fins déterminées, explicites et légitimes, et ne seront pas traitées ultérieurement d'une manière incompatible avec ces fins. Conformément à l'article 89, paragraphe 1 du RGPD, le stockage et le traitement de ces données à des fins archivistiques dans l’intérêt public, à des fins de recherche scientifique ou historique ou à des fins statistiques, n’est pas considéré comme incompatible avec les finalités initiales ;</li>
            <li className='li-rgpd'>	adéquates, pertinentes et limitées à ce qui est nécessaire dans le cadre des finalités pour lesquelles elles sont traitées ;</li>
            <li className='li-rgpd'>	exactes, et si nécessaires tenues à jour. Toutes les mesures raisonnables seront prises pour corriger des données incorrectes au regard de la finalité pour laquelle celles-si sont collectées ;</li>
            <li className='li-rgpd'>	conservées sous une forme permettant l'identification des personnes concernées pour une durée suffisante pour leur traitement ;</li>
            <li className='li-rgpd' >   traitées d'une façon qui garantit la sécurité de celles-ci, y compris en ce qui concerne le traitement non-autorisé ou illicite, la perte ou la destruction de ces données, accidentelle ou volontaire.</li>
           </ul>

           <h1 className="title">III- Consentement</h1>
           <p>En utilisant le site https://bimcasting.digitalconstruction.cloud/, les utilisateurs consentent à </p>
           <ul>
            <li>	Toutes les conditions incluses dans la présente politique de confidentialité. </li>
            <li>	La collecte, l'utilisation et la conservation des données listées dans la présente politique de confidentialité.</li>
           </ul>

           <h1 className="title">IV- Données personnelles collectées</h1>

           <p>
                Les données personnelles suivantes collectées et utilisées sur le site  https://bimcasting.digitalconstruction.cloud/ sont : l'addresse email, la date de naissance, le sexe, le nom et le prenom.
                Aucune donnée supplémentaire n'est collectée sans vous en informer au préalable.

 

            </p>
            <h1 className="title">V- Traitement de ces données</h1>

            <p>Les données personnelles recueillies sur le site https://bimcasting.digitalconstruction.cloud/ ne sont collectées et traitées que dans le cadre des fins précisées dans la présente politique de confidentialité et / ou dans les pages pertinentes du site. Les données personnelles que nous collectons ne seront pas utilisées à d’autres fins.</p>
            <h1 className="title"> VI /Stockage et protection des données personnelles</h1>
            <p> Le site https://bimcasting.digitalconstruction.cloud/ ne conserve pas les données personnelles plus longtemps que ce qui est nécessaire à la réalisation des fins pour lesquelles elles sont collectées.</p>
            <p> Nous prenons des mesures de sécurité appropriées pour protéger vos données personnelles contre tout accès non autorisé ou utilisation abusive.</p>

            <h1 className="title">VII- Droits de l'utilisateur</h1>
            <p>Conformément au chapitre 3, art 12-23 du RGPD, les utilisateurs du site  https://bimcasting.digitalconstruction.cloud/ ont, en ce qui concerne leurs données personnelles, les droits suivants :</p>
            <ul className='ul-rgpd'>
                <li className='li-rgpd'>	Droit d'accès</li>
                <li className='li-rgpd'>	Droit de rectification</li>
                <li className='li-rgpd'>	Droit à l'effacement</li>
                <li className='li-rgpd'>	Droit d'objection</li>
                <li className='li-rgpd'>	Droit de restreindre le traitement</li>
                <li className='li-rgpd'>	Droit de restreindre le traitement</li>
                
            </ul>
            <p>Pour faire valoir l'un de ses droits, accéder à vos données, les modifier ou les supprimer d'une quelconque manière, vous pouvez communiquer nous contacter via les addresses email suivantes:</p>

            <h1 className="title">IX- Modification de la politique de confidentialité</h1>

            <p>Afin de rester en accord avec la loi ou de refléter tout changement dans notre processus de gestion des données personnelles, la présente politique de confidentialité peut être amenée à changer et à être modifiée régulièrement. Il est recommandé aux utilisateurs de vérifier régulièrement cette politique afin de se tenir informés de notre politique en termes de collecte et de traitement de données personnelles.</p>
            
            <h1 className="title" >X- Contact</h1>
            <p>Si vous avez des questions concernant notre politique de confidentialité ou la manière dont nous traitons vos données, n'hésitez pas à nous contacter à l'addresse email suivant: brcronld@gmail.com.</p>

        </div>
        
    </div>
    
    );
};

export default Rgpd;