import React,{ useState,useEffect } from 'react';
import { BrowserRouter, Route , Routes, useNavigate, Switch } from "react-router-dom";
import Login from './pages/Login';
import Projects from './pages/Projects';
import NewProject from './pages/NewProject';
import Dashboard from './pages/Dashboard';
import Order from './pages/Order';
import LoginGoogle from './pages/LoginGoogle';
import Check from './pages/Check';
import Rgpd from './pages/Rgpd';
import axios from 'axios';





const AllRoutes = () => {

    const [isAuthenticated,setIsAuthenticated] = useState(false);
    const AuthUrl = process.env.REACT_APP_API_HOST+`api/auth`;
    const navigate = useNavigate();
    console.log(isAuthenticated)

        /**
     * Vérifie l'authentification de l'utilisateur en effectuant une requête GET vers l'URL d'authentification.
     *
     * @async
     * @function
     * @name checkAuthentication
     * @returns {void}
     */

    

    useEffect(()=>{

        const checkAuthentication = async()=>{
            const response = await axios.get(AuthUrl,{withCredentials:true})
            console.log(response.data)
            if(response.data === "authentifié"){   
                setIsAuthenticated(true)
            }else{
                //navigate('/login')
                setIsAuthenticated(false) 
            }             
        }

        
        checkAuthentication()
        // make sure to catch any error
        .catch(console.error);;
    },[])
  
    return (
        
        <Routes>
            
            <Route path="/rgpd" element={<Rgpd/>} />
            {isAuthenticated ? (
            <>
              <Route path="/" element={<Projects />} />
              <Route path="/:project_id/check/:casting_id" element={<Check />} />
              <Route path="/AddProject" element={<NewProject />} />
              <Route path="/Dashboard/:project_id" element={<Dashboard />} />
              <Route path="/test1" element={<Order />} />
              <Route path="/:project_id/order/:casting_id" element={<Order />}/>
              <Route path="*" element={<Projects />} />
            </>
          ) : 
          <Route path="*" element={<LoginGoogle/>} />}
          
        </Routes>
      
            

    );
};

export default AllRoutes;