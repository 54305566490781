import {React , useEffect , useState} from 'react';
import { MapContainer, TileLayer, useMap, useLeaflet } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { latLngBounds } from 'leaflet';
/*import iconMarker from 'leaflet/dist/images/marker-icon.png'
import iconRetina from 'leaflet/dist/images/marker-icon-2x.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import L from 'leaflet';*/
import L from 'leaflet';
import axios from 'axios';
import MarkerProject from './MarkerProject';


const CenterMap = (props) => {
  const map = useMap()
  const { projects } = props;

  try {
    if (projects.length > 0) {
      const bounds = latLngBounds(projects.map(project => project.coordinates));
      map.fitBounds(bounds);
      map.setZoom(10);
    }
  } catch (error) {
    console.error("Erreur lors de la mise à jour des limites de la carte : ", error);
  }
  return null;  

}



const ProjectList = ({ projects, onCardClick }) => {
  return (
    <div className="project-list">
      {projects.map((project, index) => (
        <div className="project-card" key={index} onClick={() => onCardClick(project)}>
          <h3>{project.project_name}</h3>
          <p>{project.project_description}</p>
          {/* Ajoutez d'autres informations du projet ici */}
        </div>
      ))}
    </div>
  );
};


const Maps = () => {
  const APIkey = process.env.REACT_APP_API_KEY;
  const GEOCODING = process.env.REACT_APP_OPEN_CAGE_DATA;
  const getProjectUrl = process.env.REACT_APP_API_HOST + 'api/projects';
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);


  
  // Gestionnaire de clic de carte
  const handleCardClick = (project) => {
    // Faites quelque chose avec le projet sélectionné, par exemple, ouvrez une modale
    setSelectedProject(project);
  };


  /**
   * get an address coordonnates.
   *
   * @function
   * @name geocodeAddress
   * @param {string} address - address to get geocoded.
   * @returns {Promise<{lat: number, lng: number}> | null} - A promise with the geographic coordinates (latitude and longitude) of the address, or null if the address could not be geocoded.
   */
  const geocodeAddress = (address) => {
    const apiUrl = `https://api.opencagedata.com/geocode/v1/json?key=${GEOCODING}&q=${encodeURIComponent(address)}&pretty=1`;
    return axios.get(apiUrl)
      .then(response => {
        const { results } = response.data;
        if (results.length > 0) {
          const { lat, lng } = results[0].geometry;
          return { lat, lng };
        } else {
          console.log('Adresse introuvable:', address);
          return null;
        }
      })
      .catch(error => {
        console.log('Erreur lors de la requête de géocodage', error);
        return null;
      });
  };
  
  const getAllProject = () => {
    axios.get(getProjectUrl, { withCredentials: true })
      .then(result => {
        const data = result.data;

        // Convert address into coordinates
        const promises = data.map(project =>
          geocodeAddress(project.project_address)
            .then(coordinates => {
              return {
                ...project,
                coordinates: coordinates
              };
            })
        );

        Promise.all(promises)
          .then(projectsWithCoordinates => {
            setProjects(projectsWithCoordinates);
             // Calculer les limites géographiques des marqueurs
             
             
          })
          .catch(error => {
            console.log('Erreur lors de la conversion des adresses en coordonnées', error);
          });
      })
      .catch(error => {
        console.log('Erreur lors de la requête API', error);
      });
  };

  const deleteProject = async (projectId) => {
    try {
      await axios.delete(process.env.REACT_APP_API_HOST + `api/projects/${projectId}`, { withCredentials: true });
      getAllProject();
    } catch (error) {
      console.log('Erreur lors de la suppression du projet', error);
    }
  };

  // Define your geocodeAddress function here

  useEffect(() => {
    getAllProject();
  }, []);

  return (
    <MapContainer center={[50.84412746075126, 4.346185725867165]} zoom={8} style={{ height: '100%', width: '100%' }}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {projects.map((project, index) => (
        <MarkerProject
          key={index}
          position={[project.coordinates.lat, project.coordinates.lng]}
          name={project.project_name}
          id={project.project_id}
          deleteProject={deleteProject}
        />
      ))}
      <CenterMap 
      projects={projects} 
      />
    </MapContainer>
   
  );
};

export default Maps;