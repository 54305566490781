// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* html, body, #root {\n    height: 100vh;\n  }\n  \n\n\n.App{\n    height: 100vh;\n    \n    margin: 0;\n    padding: 0;\n    font-family: 'Roboto', sans-serif;\n -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale\n\n}\n\n#page{\n    height: 100vh;\n   \n    margin: 0;\n    padding: 0;\n\n}*/\n[data-reactroot] \n        {height: 100% !important; }\n*{\n    margin: 0;\n    padding: 0;\n    font-family: \"neue-haas-unica\", sans-serif;\n    scroll-behavior: smooth;\n    -webkit-font-smoothing: antialiased;\n/*font-family: \"HelveticaNeue-Light\", \"Helvetica Neue Light\", \"Helvetica Neue\", Helvetica, Arial, \"Lucida Grande\", sans-serif; */\n}", "",{"version":3,"sources":["webpack://./src/settings.css"],"names":[],"mappings":"AAEC;;;;;;;;;;;;;;;;;;;;;;;EAuBC;AACF;SACS,uBAAuB,EAAE;AAClC;IACI,SAAS;IACT,UAAU;IACV,0CAA0C;IAC1C,uBAAuB;IACvB,mCAAmC;AACvC,gIAAgI;AAChI","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');\n\n /* html, body, #root {\n    height: 100vh;\n  }\n  \n\n\n.App{\n    height: 100vh;\n    \n    margin: 0;\n    padding: 0;\n    font-family: 'Roboto', sans-serif;\n -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale\n\n}\n\n#page{\n    height: 100vh;\n   \n    margin: 0;\n    padding: 0;\n\n}*/\n[data-reactroot] \n        {height: 100% !important; }\n*{\n    margin: 0;\n    padding: 0;\n    font-family: \"neue-haas-unica\", sans-serif;\n    scroll-behavior: smooth;\n    -webkit-font-smoothing: antialiased;\n/*font-family: \"HelveticaNeue-Light\", \"Helvetica Neue Light\", \"Helvetica Neue\", Helvetica, Arial, \"Lucida Grande\", sans-serif; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
